import React, { Component } from 'react'

import { Teaser } from '../Teaser'
import {
  ComponentContainer,
  OverflowContainer,
  SubText,
} from '../../utils/globalStyledComponents'
import { GridWrapper } from './style.js'

class CategoryLanding extends Component {
  render() {


    // console.log(this.props)
    return (
      <ComponentContainer>
        <OverflowContainer>
          <SubText>
            {this.props.introHeading && <h4>{this.props.introHeading}</h4>}

            {this.props.introDesc && <p>{this.props.introDesc}</p>}
          </SubText>

          <GridWrapper>
            {this.props.itineraries
              ? this.props.itineraries.map((teaser, index) => (
                  <Teaser
                    key={index}
                    itinerary
                    days={teaser.avItinerary.summary.days}
                    img={{
                      ...teaser.avContent.featureImage,
                      sizes: '504px',
                    }}
                    descHeading={teaser.avItinerary.intro_heading}
                    title={teaser.title}
                    url={teaser.uri}
                  />
                ))
              : null}
          </GridWrapper>
        </OverflowContainer>
      </ComponentContainer>
    )
  }
}

export default CategoryLanding
