import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { StoriesWidget } from '../components/StoriesWidget'
import { ItineraryListing } from '../components/ItineraryListing'
import SEO from '../components/seo'

class PageTemplate extends Component {
  render() {
    const post = this.props.data.page
    const itineraries = this.props.data.allWpItinerary.nodes

    return (
      <Layout headerTitle={post.title} generic>
        <SEO
          image={post.avContent.featureImage?.sourceUrl}
          title={post.seo.title}
          description={post.seo.metaDesc}
        />

        <ItineraryListing
          introHeading={post.avIntro.introHeading}
          introDesc={post.avIntro.avDescription}
          itineraries={itineraries}
        />

        <StoriesWidget />
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PageTemplate

export const pageQuery = graphql` 
  query($id: String!) {
    allWpItinerary(sort: {fields: menuOrder, order: ASC}) {
 
      nodes {
        title
        uri
        avItinerary {
          summary {
            days
          }
        }
        avContent {
          featureImage {
            sourceUrl
            srcSet
          }
        }
      }
    
    }

    page:wpPage(id: { eq: $id }) {
			title
			seo {
				title
				metaDesc
			}
			content
			categories {
				nodes {
					name
					slug
				}
			}
			avContent {
				featureImage {
					sourceUrl
					srcSet
				}
			}
			avIntro {
				introDescription
				introHeading
			}
		
		}
  }
`
