import styled from 'styled-components';

export const GridWrapper = styled.div`
	display: grid;
  grid-template-columns: 1fr;
	grid-gap: 10px;
	padding-top: 30px;
	padding-bottom: 50px;

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;

	}

	@media (min-width: 768px) {
		padding-top: 30px;
		grid-gap: 30px;

		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: 1280px) {
		padding-top: 50px;

grid-gap: 55px;
padding-bottom:100px;
	}
`;
